import * as React from "react"
import { graphql } from "gatsby"
import { Layout, Header, ResultRow } from "../components"
import axios from "axios"
import { SearchResults, SearchNavigation } from "../containers/Main"

class Search extends React.Component {

  state = {
    results: [],
    defaultValue: "",
    value: "",
    count: 0,
    page: 1,
    totalPages: 0,
    nextPage: null,
    prevPage: null,
    total_results_size: 0,
    loading: true
  }

  pageSize = 10;

  componentDidMount() {
    const { location: { search } } = this.props;
    const query = search !== '' && decodeURI(
      search
      .replace('?t=', '')
      .replaceAll('+', ' ')
    );
    
    if (query) {
      this.setState({ 
        value: query.trim(),
        defaultValue: query.trim()
      });
      setTimeout(() => {
        this.handleSearch();
      }, 300);
    }
  }

  handleInput = (e) => {
    this.setState({ value: e })
  }

  handleState = (res) => {
    this.setState({ 
      results: res.data.results.filter(item => item.type !== 'oeuvrescollection'),
      count: this.state.count + 1,
      page: res.data.page,
      totalPages: res.data.total_pages,
      nextPage: res.data.next_page,
      prevPage: res.data.prev_page,
      total_results_size: res.data.total_results_size,
      loading: false
    });
  }

  
  handleRequest = (page) => {
    const { 
      location,
      data: {
        meta: {
          siteMetadata: {
            siteUrl
          }
        }
      }
    } = this.props;
    
    if (typeof window !== 'undefined') {
      const url = new URL(`${location.origin}${location.pathname}`);
      url.searchParams.set('t', this.state.value.trim());
      window.history.replaceState('', '', url);
    }

    axios.post(`${siteUrl}/.netlify/functions/search`, {
      'term': this.state.value,
      'page': page, 
      'pageSize': this.pageSize
    }).then(res => {
      this.handleState(res);
    })
  }

  handleSearch = () => {
    this.setState({ loading: true })
    
    this.handleRequest(1);
  }

  handlePageButtons = (page) => {
    this.setState({ loading: true })

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.handleRequest(page);
  }
  
  handlePrevNext = (dir) => {
    let url = null;
    this.setState({ loading: true })

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    switch (dir) {
      case "prev":
        url = this.state.prevPage
        break;

      case "next":
        url = this.state.nextPage
        break;

      default: 
        url = this.state.nextPage
        break;
    }
    
    if (url) {
      axios.get(url)
      .then((res) => {
        this.handleState(res);
      });
    }
  }

  render() {
    let navigation = [];

    for (let i = 1; i <= this.state.totalPages; i++) {
      navigation.push(i);
    }
    
    return (
      <Layout>
        <div>
          <Header 
            handleSearch={this.handleSearch} 
            handleInput={this.handleInput}
            defaultValue={this.state.defaultValue}
          />

          <SearchResults className={`${this.state.loading ? 'loading' : 'loaded'}`}>
            <p className="resultat">
              {this.state.total_results_size}
              {` `}
              {this.state.total_results_size > 1
                ? "résultats trouvés"
                : "résultat trouvé"}
            </p>
            <ul>
              {this.state.results.map((i, index) => {
                return <ResultRow key={`result-${index}`} i={i} />;
              })}
            </ul>
          </SearchResults>

          <SearchNavigation>
            {this.state.prevPage ? (
              <button onClick={() => this.handlePrevNext("prev")}>
                Précédent
              </button>
            ) : (
              <button disabled>
                Précédent
              </button>
            )}

            {navigation.map((i, index) => {
              return (
                <button
                  className={this.state.page === i ? 'current' : ''}
                  key={index}
                  onClick={() => this.handlePageButtons(i)}
                >
                  {i}
                </button>
              );
            })}

            {this.state.nextPage ? (
              <button onClick={() => this.handlePrevNext("next")}>
                Suivant
              </button>
            ) : (
              <button disabled>
                Suivant
              </button>
            )}
          </SearchNavigation>
        </div>
      </Layout>
    );
  }
}

export default Search;
export const articleQuery = graphql`
  query metaList {
    meta: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`